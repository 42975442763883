<template>
  <div class="ad-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" size="small" label-width="180px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 30%"></el-input>
      </el-form-item>
      <el-form-item class="source-img-form-item" :label="$t('gpt.modelImage')" required>
        <el-upload :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png"
          :headers="{ Authorization: this.token }" list-type="picture-card" :file-list="sourceFileList"
          :on-change="handleChangeSource" :on-remove="handleRemoveSource" :data="{ group: 'gpt' }" :multiple="false"
          :limit="1">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item class="source-img-form-item" :label="$t('gpt.productImage')" required>
        <el-upload :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png"
          :headers="{ Authorization: this.token }" list-type="picture-card" :file-list="targetFileList"
          :on-change="handleChangeTarget" :on-remove="handleRemoveTarget" :data="{ group: 'gpt' }" :multiple="false"
          :limit="1">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createTask, fetchTargets } from "@/api/gpt";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import { imageUrl } from "@/filters";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      total: 0,
      form: {
        desc: "",
        sourceIds: [],
        targetIds: [],
      },
      sourceFileList: [],
      targetFileList: [],
      loading: false,
    };
  },
  methods: {
    initForm() {
      this.form = {
        desc: "",
        sourceIds: [],
        targetIds: [],
      }
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.sourceFileList.length === 0) {
            this.$message({
              message: "Please upload model image",
              type: "warning",
            });
            return;
          }
          if (this.targetFileList.length === 0) {
            this.$message({
              message: "Please upload product image",
              type: "warning",
            });
            return;
          }
          let params = {
            ...this.form, category: "ad", sourceIds: [], targetIds: []
          }
          let finish = true;
          this.sourceFileList.forEach((t) => {
            if (t.status !== "success") {
              finish = false;
              return;
            }
            if (
              t.response &&
              t.response.result &&
              t.response.result.length > 0
            ) {
              params.sourceIds.push(t.response.result[0].id);
            }
          });
          this.targetFileList.forEach((t) => {
            if (t.status !== "success") {
              finish = false;
              return;
            }
            if (
              t.response &&
              t.response.result &&
              t.response.result.length > 0
            ) {
              params.targetIds.push(t.response.result[0].id);
            }
          });
          if (!finish) {
            this.$message({
              message: "Image upload not finish, can't submit",
              type: "warning",
            });
            return;
          }
          this.$confirm(
            this.$t("tagger.submitTask"),
            this.$t("common.pleaseConfirm"),
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
            this.loading = true;
            createTask(params).then((response) => {
              this.loading = false;
              if (response.success) {
                this.$notify({
                  title: this.$t("common.saveSucc"),
                  message: this.$t("common.indirecting"),
                  type: "success",
                });
                let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                this.$router.push("/services/gpt-ad/ad" + suffix).catch(() => { });
              }
            })
              .catch(() => {
                this.loading = false;
              });
          })
        }
      });
    },
    fetchData(i) {
      let idx = i;
      if (i !== 0 && i !== 1) {
        this.targets.splice(0, 1, [])
        this.targets.splice(1, 1, [])
        idx = 0;
      }
      fetchTargets(this.listQuery[idx]).then(response => {
        if (response.success) {
          if (i !== 0 && i !== 1) {
            this.targets.splice(0, 1, response.result && response.result.items ? response.result.items : []);
            this.targets.splice(1, 1, response.result && response.result.items ? response.result.items : []);
          } else {
            this.targets.splice(i, 1, response.result && response.result.items ? response.result.items : []);
          }
          this.total = response.result.totalCount;
        }
      })
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    handleChangeSource(file, fileList) {
      this.sourceFileList = fileList;
    },
    handleRemoveSource(file, fileList) {
      this.sourceFileList = fileList;
    },
    handleChangeTarget(file, fileList) {
      this.targetFileList = fileList;
    },
    handleRemoveTarget(file, fileList) {
      this.targetFileList = fileList;
    },
  },
  mounted() {
    this.initForm();
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.ad-new {
  .form {
    margin-top: 25px;
  }

  /deep/ .el-upload--picture-card {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list__item-status-label i {
    position: absolute;
    right: 15px
  }

  .target-container {
    margin-top: 15px;

    /deep/.el-card__header {
      padding: 5px 15px;
    }

    /deep/.el-card__body {
      padding: 5px;
    }

    .target-block {
      text-align: center;

      .target-img {
        max-width: 80px;
      }
    }

    .pagination-container {
      margin: 0;
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}
</style>